<template>
  <div @scroll="handleScroll" ref="scrollContainer" class="scroll-container">
    <draggable :list="items"  :animation="200" ghost-class="ghost-card" group="tasks" @change="onTaskMoved">
        <KanbanTask :key="index" :task="item" v-for="(item, index) in items" @open_drawer_request="open_drawer_request"/>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import PaginationMixin from "@/Mixins/PaginationMixin";
import axios from "@/configs/axios";
import KanbanTask from "@/components/Kanban/KanbanTask";

export default {
  props:{
    board:{
      type:Object,
      required:true,
    }
  },
  components: {
    KanbanTask,
    draggable,
  },
  data() {
    return {
      items: [],
      other_params:{status_request:this.board.uid},
      loading: false,
    };
  },
  mixins: [
      PaginationMixin
  ],
  methods: {
    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        this.infiniteHandle(false);
      }
    },
    function_to_get_list(params) {
      return new Promise((resolve, reject) => {
        axios.get('organizations/pages_kanban_list/', {params: {...params}})
            .then(async (responce) => {
              responce = responce.data
              if (this.items) {
                this.items.push(...responce.results)
              } else {
                this.items = responce.results
              }
              return resolve(responce)
            })
            .catch((error) => {
              console.log(error)
              return reject(error)
            })
      })
    },
    onTaskMoved(event) {
      if (event.added){
        const movedTask = event.added.element;
        const newStatus = this.board.uid; // Обновите это значение на новый статус
        axios.patch(`organizations/drawer_change_status/${movedTask.uid}/`, { status_request: newStatus })
            .then(response => {
              this.$message.success(this.$t('success'));
              console.log('Task status updated successfully:', response.data);
            })
            .catch(error => {
              this.$message.error('error')
              console.error('Error updating task status:', error);
            });

      }
      // const movedTask = this.items[event.moved.newIndex];
      // const newStatus = this.board.uid; // Обновите это значение на новый статус
      //
      // axios.patch(`organizations/kanban_task/${movedTask.id}/`, { status: newStatus })
      //     .then(response => {
      //       console.log('Task status updated successfully:', response.data);
      //     })
      //     .catch(error => {
      //       console.error('Error updating task status:', error);
      //     });
    },
    open_drawer_request(task){
      console.log('task', task)
      this.$emit('open_drawer_request', task)
    }
  },
  mounted() {
    // Инициировать искусственное событие скроллинга
    this.$nextTick(() => {
      const container = this.$refs.scrollContainer;
      const scrollEvent = new Event('scroll');
      container.dispatchEvent(scrollEvent);
    });
  },
};
</script>

<style>
.scroll-container {
  overflow-y: auto;
}
.item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.loading {
  text-align: center;
  padding: 10px;
}
</style>
